import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Form, Button, FloatingLabel, Alert } from 'react-bootstrap';
import Api from "../../services/Api";
import ApiMServer from "../../services/ApiMServer"

const Signin = () => {
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async () => {
    if (!id) {
      setError("Preencha o ID");
      return;
    }
    if (id === process.env.REACT_APP_KEY) {
      try {
        let { data } = await Api.get(`/empresas/listempresas`, { headers: { "x-access-token": process.env.REACT_APP_API_KEY } })
        localStorage.setItem('empresas', JSON.stringify(data));
        navigate("/medidas");
      } catch (err) {
        setError('Erro ao carregar.')
      }
    } else {
      try {
        let { data } = await ApiMServer.post(`/company/detail`, { id });
        localStorage.setItem('company', JSON.stringify(data));

        navigate(`/telemetria/${id}`);
      } catch (err) {
        setError('ID inválido.')
      }
    }
  }

  /*   useEffect(() => {
      const empresaLocal = JSON.parse(localStorage.getItem('empresa'))
      const empresasLocal = JSON.parse(localStorage.getItem('empresas'))
      if (empresaLocal) {
        navigate("/dashboard");
      } else if (empresasLocal) {
        navigate("/medidas");
      } else {
        navigate("/");
      }
    }, []) */

  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center vh-100"
      style={{
        backgroundImage: `url(${'./img/loginWeb.jpg'})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Container
        className="p-4 border rounded shadow-sm"
        style={{
          maxWidth: '420px',
          backgroundColor: 'rgba(255, 255, 255, 0.6)'
        }}
      >
        <h3 className="text-center mb-4">MOTOMCO</h3>
        <Form>
          <FloatingLabel controlId="floatingId" label="ID" className="mb-3">
            <Form.Control
              type="text"
              placeholder="ID"
              value={id}
              onChange={(e) => {
                setId(e.target.value);
                setError('');
              }}
            />
          </FloatingLabel>

          {error && <Alert className="p-2" variant="danger">{error}</Alert>}

          <Button
            variant="success"
            className="w-100"
            onClick={handleLogin}
            size="lg"
          >
            Entrar
          </Button>

        </Form>
      </Container>
    </Container>
  );
};
export default Signin;