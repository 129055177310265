import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Grafico from "./components/Grafico";
import Reload from "../../components/Reload/Reload"
import { useParams } from "react-router-dom";
import ApiMServer from "../../services/ApiMServer";
import ApiGServer from "../../services/ApiGServer"

const Telemetria = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [company, setCompany] = useState({});
  const [progress, setProgress] = useState(0);
  const [reload, setReload] = useState(false);
  const [detailsEquip, setDetailsEquip] = useState([]);

  const reloadRef = useRef(reload);

  const handleCompany = async () => {
    console.log("handleCompany()");

    try {
      let { data } = await ApiMServer.post(`/company/detail`, { id });
      setCompany(data);
      localStorage.setItem('company', JSON.stringify(data));
      if (data?.equipments?.length) {
        let equip = await ApiGServer.post(`equipment/filter`, { filter: data?.equipments })
        setDetailsEquip(equip.data)
      }
    } catch (error) {
      console.error('Erro ao buscar medidas:', error?.message);
    }
    setIsLoading(false)
  }

  useEffect(() => {
    handleCompany();
    //----------------------------------------------------------------------
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          const currentReload = reloadRef.current;
          setReload(!currentReload);
          return 0;
        }
        return prev + 3.33;
      });
      handleCompany();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    reloadRef.current = reload;
  }, [reload]);

  return (
    <Container className="pt-2" style={{ background: '#1c4220', minHeight: "100vh" }} fluid>
      {isLoading && <Reload />}
      <Row className="mb-2">
        <Col>
          <div style={{
            width: '100%',
            backgroundColor: '#f4f4f4',
            borderRadius: '5px',
            border: "solid 1px #f4f4f4",
            overflow: 'hidden'
          }}>
            <div
              style={{
                width: `${Math.min(progress, 100)}%`,
                height: 8,
                backgroundColor: '#1c4220',
                borderRadius: '5px',
                transition: 'width 1s linear',
              }}
            ></div>
          </div>
        </Col>
      </Row>
      <Row>
        {detailsEquip?.length && company?.equipments?.map((serial) => (
          <Grafico
            key={serial}
            company={company}
            serial={serial}
            reload={reload}
            detailsEquip={detailsEquip}
          />
        ))}
      </Row>
    </Container>
  );
};

export default Telemetria;
