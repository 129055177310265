import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Fragment } from "react";
import useAuth from './hooks/useAuth'
import Dashboard from './pages/Dashboard/Dashboard'
import Sensores from './pages/sensores/Sensores';
import Login from './pages/Login/Login'
import Silo from './pages/Silo/Silo';
import Telemetria from './pages/Telemetria/Telemetria';
import Medidas from './pages/Medidas/Medidas';

function App() {
  const Private = ({ Item }) => {
    const signed = useAuth();
    return signed ? <Item /> : <Login />;
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Fragment>
          <Routes>
            <Route exact path="/dashboard" element={<Private Item={Dashboard} />} />
            <Route exact path="/medidas" element={<Medidas />} />
            <Route exact path="/sensores" element={<Private Item={Sensores} />} />
            <Route exact path="/apresentacao" element={<Private Item={Silo} />} />

            <Route exact path="/telemetria/:id" element={<Telemetria />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Fragment>
      </BrowserRouter>
    </div>
  );
}
export default App;