import { useMemo } from 'react';

const useAuth = () => {
  const isAuthenticated = useMemo(() => {
    try {
      const empresa = localStorage.getItem('company');
      return !!empresa && JSON.parse(empresa);
    } catch (error) {
      console.error('Erro ao verificar autenticação:', error);
      return false;
    }
  }, []);

  return isAuthenticated;
};

export default useAuth;
