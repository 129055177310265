function Reload() {

  return (
      <div className="reload-overlay">
          <div className="loader"></div>
      </div>

  );
}

export default Reload;